<!--  -->
<template>
  <div class="container">
    <div class="toper">
      <div class="icon-badge" >
        <img class="auto_img" src="../assets/images/1.png" alt="" v-if="rank == 1">
        <img class="auto_img" src="../assets/images/2.png" alt="" v-if="rank == 2">
        <img class="auto_img" src="../assets/images/3.png" alt="" v-if="rank == 3">
      </div>
      <div class="user-icon-wrapper">
        <img class="user-icon"
             :src="loadUserIcon()" />
        <svg v-if="notEmpty(rank)"
             class="icon-crown"
             aria-hidden="true">
          <use :xlink:href="loadRank()"></use>
        </svg>
      </div>
      <div class="user-name">{{userName | lineStrFormat(that)}}</div>
      <div class="total-wrapper">
        <div class="icon-coin">
          <img class="auto_img" src="../assets/images/金币.png" alt="">
        </div>
        <span class="total-money">{{value | lineStrFormat(that)}}</span>
        <span class="total-unit">{{unit | emptyStrFormat(that)}}</span>
      </div>
    </div>
  </div>
</template> 

<script>
import common from "@/mixins/common"
import defBadge from '@/assets/images/yonghu.png'
export default {
  mixins: [common],
  props: {
    rank: Number,
    userIcon: String,
    userName: String,
    value: Number || String,
    unit: String
  },
  data () {
    //这里存放数据
    return {

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    loadUserIcon () {
      if (this.isEmpty(this.userIcon)) return defBadge;
      return this.userIcon
    },
    loadRank () {
      switch (this.rank) {
        case 1:
          return "#iconguanjun"
        case 2:
          return "#iconyajun"
        case 3:
          return "#iconjijun"
        default:
          return "#iconjijun"
      }
    }
  },
}
</script>
<style lang='less' scoped>
.container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  height: 100% ;
}
.toper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -18px;
}
.icon-badge {
  width: 40px;
  height: 40px;
  margin-top: -12px ;
  margin-bottom : 10px ;
}
.user-icon-wrapper {
  position: relative;
  // margin-top: 5px;
}
.user-icon {
  display: block;
  background-color: #eeeeee;
  width: 50px;
  height: 50px;
  border: 1px solid #bbbbbb;
  border-radius: 50%;
  margin-top: 10px;
}
.icon-crown {
  width: 36px;
  height: 36px;
  position: absolute;
  top: -6px;
  left: 0px;
}
.user-name {
  margin-top: 2px;
  font-size: 15px;
  color: #666666;
}
.total-wrapper {
  margin-top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon-coin {
    width: 20px;
    height: 20px;
    // font-size: 16px;
    // line-height: 16px;
  }
  .total-money {
    font-size: 15px;
    line-height: 15px;
    font-weight: bolder;
    margin-left: 8px;
    margin-right: 8px;
    margin-top : 2px ;
  }
  .total-unit {
    margin-top: 2px;
    font-size: 12px;
    line-height: 12px;
    color: #666666;
  }
}
</style>