<template>
  <!-- 收费情况外壳 -->
  <div class="charge_wrapper">
    <div class="titleBar" v-if="isApp"></div>
    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>收费情况</span>
      </div>
    </div>

    <div class="charge_router_box" :class="{ android: isApp }" ref="routerBox">
      <keep-alive>
        <overview v-if="pageName == 'OverView'"></overview>
        <post v-if="pageName == 'Post'"></post>
        <rank v-if="pageName == 'Rank'"></rank>
      </keep-alive>
    </div>

    <div class="charge_nav">
      <div class="nav_item">
        <div
          class="item_box"
          @click="pageName = 'OverView'"
          :class="{ active: pageName == 'OverView' }"
        >
          <span class="item_txt">总览</span>
        </div>
      </div>
      <div class="nav_item">
        <div
          class="item_box"
          @click="pageName = 'Post'"
          :class="{ active: pageName == 'Post' }"
        >
          <span class="item_txt">收费岗</span>
        </div>
      </div>
      <div class="nav_item">
        <div
          class="item_box"
          @click="pageName = 'Rank'"
          :class="{ active: pageName == 'Rank' }"
        >
          <span class="item_txt">排行榜</span>
        </div>
      </div>
    </div>

    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
// import BScroll from "better-scroll";
import Overview from "./Charge/Overview";
import Post from "./Charge/Post";
import Rank from "./Charge/Rank";
import { mapState } from "vuex";
export default {
  components: {
    Overview,
    Post,
    Rank
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp
    })
  },
  data: () => ({
    pageName: "OverView"
  }),

  methods: {
    // initBScroll() {
    //   this.$nextTick(() => {
    //     if (!this.routerBox) {
    //       this.routerBox = new BScroll(this.$refs.routerBox, {
    //         probeType: 2,
    //         click: true,
    //       });
    //     } else {
    //       this.routerBox.refresh();
    //     }
    //   });
    // },
  },
  created() {
    document.title = "智即停综合运营 - 收费情况";
  },
  mounted() {
    // this.initBScroll();
  }
};
</script>

<style lang="less" scoped>
.charge_wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #edf0f7;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.charge_router_box {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 50px;
  // flex: 1;
  // width: 100%;

  &.android {
    top: 74px;
  }
}

.charge_nav {
  // flex: 0 0 50px;
  height: 50px;
  // height: 7.5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  // margin-top: 8px;
  background-color: #fff;
  display: flex;
  border-top: 1px solid rgba(7, 17, 27, 0.1);

  .nav_item {
    flex: 1;
    font-size: 16px;
    font-weight: 600;

    .item_box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      &.active .item_txt {
        color: #238be4;
      }
    }

    .item_txt {
      display: block;
      width: 100%;
      text-align: center;
      border-right: 1px solid rgba(7, 17, 27, 0.1);
      color: #adb6bd;
    }

    &:last-child .item_txt {
      border: 0;
    }
  }
}
</style>
