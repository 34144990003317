// 通用的混入文件
const common = {
  data() {
    return {
      that: this,
      SUCCESS: 1 // 成功
    };
  },
  computed: {},
  watch: {},
  filters: {
    stringMaskFormat: val => {
      if (val) {
        if (val.length) {
          const len = val.length;
          let maskLength = len / 2;
          let startStr = val.substring(0, maskLength);
          let endStr = "";
          for (let i = 0; i < maskLength; i++) {
            endStr += "*";
          }
          return startStr + endStr;
        }
      }
      return val;
    },
    lineStrFormat: (str, that) => {
      return that.isEmpty(str) ? "-" : str;
    },
    emptyStrFormat: (str, that) => {
      return that.isEmpty(str) ? "" : str;
    }
  },
  methods: {
    notEmpty(obj) {
      return !this.isEmpty(obj);
    },
    isEmpty(obj) {
      if (
        Object.prototype.toString.call(obj) == "[object Null]" ||
        Object.prototype.toString.call(obj) == "[object Undefined]"
      ) {
        return true;
      } else if (Object.prototype.toString.call(obj) == "[object Object]") {
        if (obj != null && JSON.stringify(obj) != "{}") {
          return false;
        }
      } else if (Object.prototype.toString.call(obj) == "[object Array]") {
        if (obj != null && obj.length > 0) {
          return false;
        }
      } else if (Object.prototype.toString.call(obj) == "[object String]") {
        if (obj != null && obj.trim().length > 0) {
          return false;
        }
      } else if (
        Object.prototype.toString.call(obj) == "[object Number]" ||
        Object.prototype.toString.call(obj) == "[object Boolean]" ||
        Object.prototype.toString.call(obj) == "[object Function]"
      ) {
        return false;
      } else if (
        Object.prototype.toString.call(obj) == "[object Set]" ||
        (Object.prototype.toString.call(obj) == "[object Map]" && obj.size != 0)
      ) {
        return false;
      } else if (JSON.stringify(obj) !== {}) {
        return false;
      }
      return true;
    }
  }
};

export default common;
