<template>
  <div class="fan_wrapper">
    <div class="fan_title">
      <div class="title_txt">今日收费</div>
      <div class="title_tab">
        <div
          class="tab_item"
          :class="{ active: active == 1 }"
          @click="changeActive(1)"
        >
          今日
        </div>
        <div
          class="tab_item"
          :class="{ active: active == 3 }"
          @click="changeActive(3)"
        >
          本月
        </div>
        <div
          class="tab_item"
          :class="{ active: active == 13 }"
          @click="changeActive(13)"
        >
          上个月
        </div>
      </div>
    </div>
    <div class="fan_chart" ref="fanChart"></div>
    <div class="fan_data">
      <div class="data_item">
        <div class="item_top">
          <div class="item_icon" style="background-color : #90ec7d ;"></div>
          <div class="item_txt">线上支付</div>
        </div>
        <div class="item_bottom">
          <div class="item_cost">{{ chartData.onlinePayAmount || "0" }}元</div>
        </div>
      </div>
      <div class="data_item">
        <div class="item_top">
          <div class="item_icon" style="background-color : #f7a35b ;"></div>
          <div class="item_txt">线下支付</div>
        </div>
        <div class="item_bottom">
          <div class="item_cost">{{ chartData.offlinePayAmount || "0" }}元</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    active: 1
  }),
  props: {
    chartData: {
      type: Object,
      default: () => new Object()
    }
  },
  watch: {
    chartData(newV) {
      if (!this.fanChart) {
        // window.addEventListener("resize", function() {
        //   this.fanChart.resize()
        // })
        this.fanChart = this.$echarts.init(this.$refs.fanChart);
        console.log("fanChart == > ", newV);
      }

      // 为空则全部置零
      for (let i in newV) {
        if (!newV[i]) {
          newV[i] = 0;
        }
      }

      console.log(document.body.scrollWidth);

      let fontSize = document.body.scrollWidth > 750 ? 28 : 20;

      let fanChartOptions = this.$tools.createfanChartOptions(newV, fontSize);

      this.fanChart.setOption(fanChartOptions);
    },

    active(newV) {
      this.$emit("getFanChartData", newV);
    }
  },
  methods: {
    changeActive(value) {
      this.active = value;
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (this.fanChart) {
        this.fanChart.resize();
      }
    });
  }
};
</script>

<style lang="less" scoped>
.fan_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fan_title {
  font-size: 16px;
  padding: 8px 10px;
  position: relative;

  .title_tab {
    display: flex;
    font-size: 12px;
    color: #fff;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    .tab_item {
      padding: 4px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(206, 212, 218);

      &.active {
        background-color: #15aabf;
      }
    }
  }
}

.fan_chart {
  flex: 1;
}

.fan_data {
  border-top: 1px solid rgba(7, 17, 27, 0.1);
  height: 24%;
  width: 100%;
  display: flex;

  .data_item {
    flex: 1;
    border-right: 1px solid rgba(7, 17, 27, 0.1);
    padding: 8px;
    font-size: 14px;

    > div {
      display: flex;
      justify-content: center;
    }

    &:last-child {
      border: 0;
    }
  }
}

.item_top {
  display: flex;
  align-items: center;
  // justify-content: center;

  .item_icon {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 6px;
  }
}

.item_bottom {
  font-size: 14px;

  .item_cost {
    text-align: center;
    color: #333;
  }
}
</style>
