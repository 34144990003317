<template>
  <div class="item-wrapper">
    <span class="rank">{{ loadRank() }}</span>
    <img class="user-icon" :src="loadUserIcon()" />
    <span class="user-name">{{ userName | lineStrFormat(that) }}</span>
    <span class="space"></span>
    <div class="icon-coin">
      <img class="auto_img" src="../assets/images/金币.png" alt="" />
    </div>
    <span class="momeny">{{ value | lineStrFormat(that) }}</span>
    <span class="unit">{{ unit | lineStrFormat(that) }}</span>
  </div>
</template>

<script>
import common from "@/mixins/common";
import defBadge from "@/assets/images/yonghu.png";
export default {
  mixins: [common],
  props: {
    rank: Number,
    userIcon: String,
    userName: String,
    value: String,
    unit: String
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    loadUserIcon() {
      if (this.isEmpty(this.userIcon)) return defBadge;
      return this.userIcon;
    },
    loadRank() {
      // 暂时第3名后的排名样式上没区别，以后若有区别再额外写逻辑
      return this.rank;
    }
  },
  created() {},
  mounted() {}
};
</script>
<style lang="less" scoped>
.item-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
}
.rank {
  width: 20px;
  font-size: 18px;
  margin-right: 20px;
  text-align: right;
}
.user-icon {
  background-color: #eeeeee;
  width: 36px;
  height: 36px;
  border: 1px solid #bbbbbb;
  border-radius: 50%;
}
.user-name {
  margin-left: 20px;
  font-size: 15px;
  color: #666666;
}
.icon-coin {
  width: 20px;
  height: 20px;
}
.space {
  flex: 1;
}
.momeny {
  font-size: 16px;
  font-weight: bolder;
  margin: 0 5px;
}
.unit {
  font-size: 14px;
}
</style>
