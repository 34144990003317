<template>
  <div class="rank_wrapper" ref="rankBScroll">
    <div class="rank_BScroll">
      <div class="rank_top">
        <div class="top_title">
          <span class="title_text">收费排行榜</span>
        </div>
        <div class="top_select_wrapper">
          <div class="top_select" @click="isVisible = !isVisible">
            <span>{{ showSelectTitle }}</span>
            <span class="iconfont icon-down" :class="{ change: isVisible }">
            </span>
          </div>
          <transition name="van-slide-down">
            <div class="select_list" v-show="isVisible">
              <div
                class="select-item"
                v-for="item in selectList"
                :key="item.id"
                :class="{ active: isSelectItemActive(item) }"
                @click="onClickSelectItem(item)"
              >
                {{ item.title }}
              </div>
            </div>
          </transition>
        </div>
      </div>

      <div class="rank_tab">
        <span
          v-for="item in tabList"
          :key="item.id"
          :class="['tab-item', isTabItemActive(item) ? 'active' : '']"
          @click="onClickTabItem(item)"
          >{{ item.title }}</span
        >
      </div>
      <div class="rank-list-wrapper">
        <div class="rank-list">
          <div class="rank-list-top-wrapper">
            <div class="top-wrapper top-2">
              <toper
                :rank="2"
                :userIcon="loadRankUserIcon(2)"
                :userName="loadRankUserName(2)"
                :value="loadRankMomeny(2)"
                unit="元"
              />
            </div>
            <div class="top-wrapper top-1">
              <toper
                :rank="1"
                :userIcon="loadRankUserIcon(1)"
                :userName="loadRankUserName(1)"
                :value="loadRankMomeny(1)"
                unit="元"
              />
            </div>
            <div class="top-wrapper top-3">
              <toper
                :rank="3"
                :userIcon="loadRankUserIcon(3)"
                :userName="loadRankUserName(3)"
                :value="loadRankMomeny(3)"
                unit="元"
              />
            </div>
          </div>
          <div v-if="notEmpty(afterRankList)" class="rank-list-content">
            <div class="rank-list-srcoll">
              <rank-user-item
                v-for="(item, index) in afterRankList"
                :key="item.operatorId"
                :rank="index + 4"
                :userIcon="item.avatar"
                :userName="item.employeeName"
                :value="item.flowAmount + ''"
                unit="元"
              />
            </div>
          </div>
          <div class="tips-info" v-else>
            虚位以待……
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BScroll from "better-scroll";
import common from "@/mixins/common";
import Toper from "@/components/Toper";
import RankUserItem from "@/components/RankUserItem";
export default {
  mixins: [common],
  components: {
    Toper,
    RankUserItem
  },
  watch: {
    rankList: {
      handler(list) {
        // 获取第三名后的数据
        this.afterRankList = list.slice(3);
      }
    }
  },
  data() {
    return {
      selectItem: null,
      selectTabItem: null,
      isVisible: false,
      // selectList: [{ title: '今日', id: 4 }, { title: '昨日', id: 2 }, { title: '前日', id: 3 }],
      tabList: [
        { title: "日榜", id: 4 },
        { title: "周榜", id: 2 },
        { title: "月榜", id: 3 }
      ],
      rankList: [],
      afterRankList: [],
      pageHeiht: ""
    };
  },
  methods: {
    initBScroll() {
      this.$nextTick(() => {
        if (!this.rankBScroll) {
          this.rankBScroll = new BScroll(this.$refs.rankBScroll, {
            probeType: 2,
            click: true,
            swipeBounceTime: 400
          });
        } else {
          this.rankBScroll.refresh();
        }
      });
    },

    loadRankUser(rank) {
      let index = rank - 1;
      // 下标不可以小于0，排名数据不为空，数组中的数据长度必须不少于要取的index
      if (
        index < 0 ||
        this.isEmpty(this.rankList) ||
        this.rankList.length < rank
      )
        return null;
      return this.rankList[index];
    },
    loadRankUserIcon(rank) {
      if (this.isEmpty(this.loadRankUser(rank))) return null;
      return this.loadRankUser(rank).avatar;
    },
    loadRankUserName(rank) {
      let item = this.loadRankUser(rank);
      if (this.isEmpty(item)) return null;
      return item.employeeName;
    },
    loadRankMomeny(rank) {
      let item = this.loadRankUser(rank);
      if (this.isEmpty(item)) return null;
      return this.loadRankUser(rank).flowAmount;
    },

    isTabItemActive(item) {
      return (
        this.notEmpty(this.selectTabItem) && item.id === this.selectTabItem.id
      );
    },
    isSelectItemActive(item) {
      return this.notEmpty(this.selectItem) && item.id === this.selectItem.id;
    },
    onClickTabItem(item) {
      if (this.isEmpty(item)) return;
      this.selectTabItem = item;
      this.selectItem = this.selectList[0];
      this.loadRankData();
    },
    onClickSelectItem(item) {
      if (this.isEmpty(item)) return;
      this.selectItem = item;
      console.log(item);
      this.isVisible = false;
      this.loadRankData();
    },
    loadRankData() {
      let type = this.selectItem.id == 4 ? "" : this.selectItem.id;
      this.$tools.showLoading();
      // this.$get(this.$config.TL_CHARGE_STATUS_GET_RANKING_LIST , {
      //   dateType : this.selectTabItem.id ,
      //   type  ,
      // })
      this.$api
        .getRankingList({
          dateType: this.selectTabItem.id,
          type
        })
        .then(res => {
          this.rankList = res["data"]["data"];
          this.$tools.closeLoading();
        })
        .catch(() => {
          this.$tools.closeLoading();
        });
      // this.rankList = [
      //   {
      //     id: "1",
      //     userName: "哈喽贝比",
      //     momeny: "18069",
      //     icon:
      //       "https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1630518974,54396895&fm=26&gp=0.jpg",
      //   },
      //   {
      //     id: "2",
      //     userName: "哈喽露西",
      //     momeny: "13012",
      //     icon:
      //       "https://dss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=1767402875,696760419&fm=26&gp=0.jpg",
      //   },
      //   {
      //     id: "3",
      //     userName: "格日勒图",
      //     momeny: "11120",
      //     icon:
      //       "https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3580407515,1049967288&fm=26&gp=0.jpg",
      //   },
      //   {
      //     id: "4",
      //     userName: "酒井白吃",
      //     momeny: "10000",
      //     icon:
      //       "https://dss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2989252187,654967735&fm=26&gp=0.jpg",
      //   },
      //   {
      //     id: "5",
      //     userName: "A君",
      //     momeny: "9000",
      //     icon:
      //       "https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1306508493,2006189766&fm=26&gp=0.jpg",
      //   },
      //   {
      //     id: "6",
      //     userName: "B君",
      //     momeny: "8000",
      //     icon:
      //       "https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1220612432,2572868297&fm=26&gp=0.jpg",
      //   },
      //   {
      //     id: "7",
      //     userName: "C君",
      //     momeny: "7000",
      //     icon:
      //       "https://dss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2223756706,3188697330&fm=26&gp=0.jpg",
      //   },
      //   {
      //     id: "8",
      //     userName: "D君",
      //     momeny: "6000",
      //     icon:
      //       "https://dss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3673024313,3359619993&fm=26&gp=0.jpg",
      //   },
      //   {
      //     id: "9",
      //     userName: "E君",
      //     momeny: "5000",
      //     icon:
      //       "https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3190214567,3115602234&fm=26&gp=0.jpg",
      //   },
      //   {
      //     id: "10",
      //     userName: "F君",
      //     momeny: "4000",
      //     icon:
      //       "https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2456468987,3284231714&fm=26&gp=0.jpg",
      //   },
      //   {
      //     id: "11",
      //     userName: "G君",
      //     momeny: "3000",
      //     icon:
      //       "https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=3899775941,407460763&fm=26&gp=0.jpg",
      //   },
      //   {
      //     id: "12",
      //     userName: "H君",
      //     momeny: "2000",
      //     icon:
      //       "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=3149452346,66876364&fm=26&gp=0.jpg",
      //   },
      //   {
      //     id: "13",
      //     userName: "I君",
      //     momeny: "1000",
      //     icon:
      //       "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1183057007,4270556535&fm=26&gp=0.jpg",
      //   },
      // ];

      this.initBScroll();
      /*

        { id: "1", userName: "哈喽贝比", momeny: "18069", icon: "https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1630518974,54396895&fm=26&gp=0.jpg" },
        { id: "2", userName: "哈喽露西", momeny: "13012", icon: "https://dss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=1767402875,696760419&fm=26&gp=0.jpg" },
        { id: "3", userName: "格日勒图", momeny: "11120", icon: "https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3580407515,1049967288&fm=26&gp=0.jpg" },
        { id: "4", userName: "酒井白吃", momeny: "10000", icon: "https://dss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2989252187,654967735&fm=26&gp=0.jpg" },
        { id: "5", userName: "A君", momeny: "9000", icon: "https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1306508493,2006189766&fm=26&gp=0.jpg" },
        { id: "6", userName: "B君", momeny: "8000", icon: "https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1220612432,2572868297&fm=26&gp=0.jpg" },
        { id: "7", userName: "C君", momeny: "7000", icon: "https://dss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2223756706,3188697330&fm=26&gp=0.jpg" },
        { id: "8", userName: "D君", momeny: "6000", icon: "https://dss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3673024313,3359619993&fm=26&gp=0.jpg" },
        { id: "9", userName: "E君", momeny: "5000", icon: "https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3190214567,3115602234&fm=26&gp=0.jpg" },
        { id: "10", userName: "F君", momeny: "4000", icon: "https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2456468987,3284231714&fm=26&gp=0.jpg" },
        { id: "11", userName: "G君", momeny: "3000", icon: "https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=3899775941,407460763&fm=26&gp=0.jpg" },
        { id: "12", userName: "H君", momeny: "2000", icon: "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=3149452346,66876364&fm=26&gp=0.jpg" },
        { id: "13", userName: "I君", momeny: "1000", icon: "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1183057007,4270556535&fm=26&gp=0.jpg" }



      */
    },
    initSize() {
      this.pageHeiht = `${window.innerHeight * 0.93}px`;
    },
    initData() {
      this.selectTabItem = this.tabList[0];

      this.selectItem = this.selectList[0];
      this.loadRankData();
    }
  },
  computed: {
    selectList() {
      // let obj = [] ;
      switch (this.selectTabItem.id) {
        case 3:
          return [
            { title: "今月", id: 4 },
            { title: "上月", id: 2 },
            { title: "上上月", id: 3 }
          ];

        case 2:
          return [
            { title: "今周", id: 4 },
            { title: "上周", id: 2 },
            { title: "上上周", id: 3 }
          ];

        default:
          return [
            { title: "今日", id: 4 },
            { title: "昨日", id: 2 },
            { title: "前日", id: 3 }
          ];
      }

      // return obj ;
    },

    showSelectTitle() {
      if (this.isEmpty(this.selectItem)) return "";

      return this.selectItem.title;
    }
  },

  created() {
    this.initSize();
    this.initData();
  }
};
</script>

<style lang="less" scoped>
.rank_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  // display: flex;
  // flex-direction: column;
  background-color: #c8dae1;

  .rank_BScroll {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

.rank_top {
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
  .top_title {
    display: flex;
    .title_text {
      font-size: 22px;
      color: #333333;
    }
  }
  .top_select_wrapper {
    position: absolute;
    right: 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 49;

    .top_select {
      width: 80px;
      height: 30px;
      padding: 0 6px;
      z-index: 2;
      background-color: #c8dae1;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(7, 17, 27, 0.1);
      span {
        font-size: 16px;
      }
      .active {
        color: blue;
      }

      .iconfont {
        transition: all 0.2s;

        &.change {
          transform: rotate(90deg);
        }
      }
    }
    .select_list {
      padding-top: 3px;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      background-color: #eee;
      z-index: 1;
    }
    .select-item {
      text-emphasis: center;
      padding: 5px;
      font-size: 16px;

      &:hover {
        background-color: #333;
        color: #fff;
      }
    }
  }
}
.rank_tab {
  margin: 4px 10px;
  background-color: #363636;
  border-radius: 20px;
  display: flex;
  .tab-item {
    border-radius: 20px;
    padding: 6px 10px;
    font-size: 14px;
    flex: 1;
    text-align: center;
    color: #ffffff;
  }
  .active {
    background-color: #a4bbd2;
    color: #000000;
  }
}
.rank-list-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 90px;
  // background-color: #eceff6;
  position: relative;

  .rank-list {
    flex: 1;
    width: calc(100% - 24px);
    position: relative;
    top: -20px;
    padding: 10px 10px 0;
    box-shadow: 0 -5px 10px #aaaaaa;
    background-color: #ffffff;
    margin: 0 12px;
    // height: calc(100% + 52px);
    border-radius: 10px;
    // border: 1px solid #aaa;
    // overflow: hidden;
    display: flex;
    flex-direction: column;

    .rank-list-top-wrapper {
      position: relative;
      top: -10px;
      display: flex;
      .top-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: #ffffff;
        z-index: 0;
      }
      .top-1 {
        width: 36%;
        top: -20px;
        border: 1px solid #eeeeee;
        border-radius: 2px;
        box-shadow: 0px -3px 4px 0px #aaaaaa;
        z-index: 1;
      }
      .top-2 {
        width: 32%;
      }
      .top-3 {
        width: 32%;
      }
    }
    .rank-list-content {
      // position: relative;
      // top: -10px;
      margin-top: -12px;
      flex: 1;
      display: flex;
      flex-direction: column;
      // overflow: hidden;

      .rank-list-srcoll {
        width: 100%;
        position: relative;
        // padding: 0 30px;/
        // left: -10%;
        // right: -10%;
        // overflow-x: hidden;
        // overflow-y: auto;
      }
    }
    .tips-info {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #bbbbbb;
      font-size: 18px;
    }
  }
}

// .rank_fan {
//   flex: 1;
//   width: 100%;
//   padding: 8px;

//   .fan_box {
//     width: 100%;
//     height: 100%;
//     background-color: #fff;
//     border-radius: 4px;
//   }
// }

// .rank_discount {
//   flex: 1;
//   width: 100%;
//   padding: 0 8px;

//   .discount_box {
//     width: 100%;
//     height: 100%;
//     background-color: #fff;
//     border-radius: 4px;
//   }
// }
</style>
