<template>
  <div class="line_wrapper">
    <div class="line_top">
      <div class="line_title">收费趋势</div>
      <div class="line_btn">
        <div
          class="btn_item"
          :class="{ active: dateType == 1 }"
          @click="changeDataType(1)"
        >
          近7天
        </div>
        <div
          class="btn_item"
          :class="{ active: dateType == 21 }"
          @click="changeDataType(21)"
        >
          近7周
        </div>
        <div
          class="btn_item"
          :class="{ active: dateType == 11 }"
          @click="changeDataType(11)"
        >
          近半年
        </div>
      </div>
    </div>

    <div class="line_chart" ref="lineChart"></div>
  </div>
</template>

<script>
export default {
  props: {
    lineChartData: {
      type: Object,
      default: () => new Object()
    },
    dateType: {
      type: Number,
      default: 0
    }
  },
  watch: {
    lineChartData(newV) {
      console.log(newV);
      if (!this.lineChart) {
        this.lineChart = this.$echarts.init(this.$refs.lineChart);
      }

      let options = this.$tools.createLineChartOptions(newV);

      this.lineChart.setOption(options);
    }
  },
  data: () => ({
    btnActive: 0
  }),
  methods: {
    changeDataType(type) {
      this.$emit("changeDataType", type);
      this.$nextTick(() => {
        this.lineChart.resize();
      });
    }
  },
  mounted() {
    this.$nextTick(() => {});

    window.addEventListener("resize", () => {
      this.$nextTick(() => {
        if (this.lineChart) {
          this.lineChart.resize();
        }
      });
    });
  }
};
</script>

<style lang="less" scoped>
.line_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.line_top {
  display: flex;
  justify-content: space-between;

  .line_title {
    font-size: 16px;
    padding: 8px 10px;
  }

  .line_btn {
    padding: 10px;
    font-size: 14px;
    display: flex;

    .btn_item {
      padding: 4px 6px;
      background-color: #cfd4da;
      color: #fff;

      &.active {
        background-color: #15aac0;
      }
    }
  }
}

.line_chart {
  flex: 1;
}
</style>
