<template>
  <div class="overview_wrapper" ref="overVideoBScroll">
    <div class="overvideo_BScroll">
      <div class="overview_top">
        <div class="top_title">
          <span class="title_text">总车位</span>
          <span class="title_num">{{
            overviewData.parkingSpaceQty || "0"
          }}</span
          >个
        </div>
        <div
          class="top_btn"
          @click="$router.push({ name: 'Report', query: { reportType: 1 } })"
        >
          <span class="icon icon-airplane"></span>
          总报表
        </div>
      </div>

      <div class="overview_fan">
        <div class="fan_box">
          <fan-chart
            :chartData="overviewData"
            @getFanChartData="getFanChartData"
          ></fan-chart>
        </div>
      </div>

      <div class="overview_focus">
        <div class="focus_box">
          <div class="focus_title">今日关注</div>
          <div class="focus_content">
            <div class="focus_item" @click="gotoPlateNumberView(40)">
              <div class="item_num">{{ todayObj.arrear }}</div>
              <div class="item_name">欠费车</div>
            </div>
            <div class="line"></div>
            <div class="focus_item" @click="gotoPlateNumberView(20)">
              <div class="item_num">{{ todayObj.free }}</div>
              <div class="item_name">免费车</div>
            </div>
            <div class="line"></div>
            <div class="focus_item" @click="gotoPlateNumberView(21)">
              <div class="item_num">{{ todayObj.whiteList }}</div>
              <div class="item_name">白名单</div>
            </div>
          </div>
        </div>
      </div>

      <div class="overview_discount">
        <div class="discount_box">
          <line-chart
            :lineChartData="lineChartData"
            :dateType="dateType"
            @changeDataType="changeDataType"
          ></line-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BScroll from "better-scroll";
import FanChart from "../../components/FanChart";
import LineChart from "../../components/LineChart";
export default {
  components: {
    FanChart,
    LineChart
  },

  data: () => ({
    overviewData: {},
    lineChartData: {},
    fanDateType: 1, //饼图type值
    dateType: 1,
    todayObj: {
      whiteList: 0,
      free: 0,
      arrear: 0
    }
  }),
  methods: {
    initBScroll() {
      this.$nextTick(() => {
        if (!this.overVideoBScroll) {
          this.overVideoBScroll = new BScroll(this.$refs.overVideoBScroll, {
            probeType: 2,
            click: true,
            swipeBounceTime: 400
          });
        } else {
          this.overVideoBScroll.refresh();
        }
      });
    },

    gotoPlateNumberView(state) {
      this.$router.push({
        name: "Attention",
        query: {
          pageState: state
        }
      });
    },

    changeDataType(dataType) {
      this.dateType = dataType;
      this.requestLineChartData();
    },

    async requestLineChartData() {
      // let lineRes = await this.$get(this.$config.TL_CHARGE_STATUS_GET_INCOME, {
      //   dateType: this.dateType
      // });
      let lineRes = await this.$api.getIncome({
        dateType: this.dateType
      });

      console.log("requestLineChartData ==> ", lineRes);

      this.lineChartData = lineRes["data"]
        ? lineRes["data"]
        : this.$config.lineChartData;
    },

    async getFanChartData(dateType) {
      // let fanRes = await this.$get(
      //   this.$config.TL_CHARGE_STATUS_GET_DAY_CHARGE,
      //   { dateType }
      // );
      this.fanDateType = dateType;
      let fanRes = await this.$api.getDayCharge({ dateType });

      console.log("fan res ==> ", fanRes);

      this.overviewData = fanRes["data"]["data"]
        ? fanRes["data"]["data"]
        : this.$config.overviewData;
    },

    getTodayFocus() {
      // this.$get(this.$config.TL_CHARGE_STATUS_GET_TODAY_FOCUS)
      this.$api.getTodayFocus().then(res => {
        this.todayObj = res["data"];
      });
    },

    async initData() {
      let fanRes = await this.$api.getDayCharge({ dateType: this.fanDateType });
      // let fanRes = await this.$get(
      //   this.$config.TL_CHARGE_STATUS_GET_DAY_CHARGE,
      //   null
      // );

      console.log("fan res ==> ", fanRes);

      this.overviewData = fanRes["data"]["data"]
        ? fanRes["data"]["data"]
        : this.$config.overviewData;

      this.requestLineChartData();

      this.initBScroll();

      this.getTodayFocus();
    }
  },
  created() {
    this.$tools.entering();

    this.initData();

    clearInterval(this.refreshDataTimer);
    this.refreshDataTimer = setInterval(() => {
      this.initData();
    }, 10000);
  },

  beforeDestroy() {
    if (this.refreshDataTimer) {
      clearInterval(this.refreshDataTimer);
    }
  }
};
</script>

<style lang="less" scoped>
.overview_wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.overview_top {
  height: 50px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  .top_title {
    display: flex;
    align-items: center;
    font-size: 16px;

    .title_text {
      font-size: 16px;
      font-weight: 600;
      color: #3a414b;
    }

    .title_num {
      color: #41c057;
      font-size: 22px;
      margin: 0 10px;
    }
  }

  .top_btn {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 2px 16px;
    background-color: #a7d2f5;
    color: #fff;
    border-radius: 25px;

    .icon {
      font-size: 24px;
      margin-right: 6px;
    }
  }
}

.overview_fan {
  flex: 1 0 auto;
  height: 270px;

  width: 100%;
  padding: 8px;

  .fan_box {
    width: 100%;
    height: 100%;

    background-color: #fff;
    border-radius: 4px;
  }
}

.overview_discount {
  flex: 1 0 auto;
  height: 270px;
  width: 100%;
  padding: 0 8px;
  margin-bottom: 8px;

  .discount_box {
    width: 100%;
    height: 100%;

    background-color: #fff;
    border-radius: 4px;
  }
}

.overview_focus {
  flex: 0 0 120px;
  font-size: 16px;
  width: 100%;
  height: 120px;
  padding: 0 8px;
  margin-bottom: 8px;

  .focus_box {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .focus_title {
      padding: 8px 10px;
    }

    .focus_content {
      flex: 1;
      display: flex;
      align-items: center;

      .focus_item {
        flex: 1;

        > div {
          text-align: center;
          margin-bottom: 10px;
        }
      }
    }

    .line {
      height: 50px;
      width: 1px;
      background-color: rgba(7, 17, 27, 0.1);
    }
  }
}
</style>
