<template>
  <div class="post_wrapper">
    <div class="post_top" @click="clickShowPicker">
      <div class="top_title">
        <span class="icon icon-switch"
          ><span class="path1"></span><span class="path2"></span
          ><span class="path3"></span
        ></span>
        <span class="title_text">{{
          postList.length > 0 ? postValue.text : "无"
        }}</span>
      </div>
      <div class="top_total">
        <span class="total_num">{{ chartData.parkingSpaceQty || "0" }}</span>
        个车位
      </div>
    </div>

    <div class="post_fan">
      <div class="fan_box">
        <fan-chart
          :chartData="chartData"
          @getFanChartData="changeFanChartData"
        ></fan-chart>
      </div>
    </div>

    <div class="post_discount">
      <div class="discount_box">
        <line-chart
          :lineChartData="lineChartData"
          :dateType="dateType"
          @changeDataType="changeDataType"
        ></line-chart>
      </div>
    </div>

    <div
      class="post_report"
      @click="$router.push({ name: 'Report', query: { reportType: 2 } })"
    >
      <span class="icon icon-airplane"></span>
      详细报表
    </div>

    <van-popup v-model="showPicker" round position="bottom">
      <div class="picker">
        <van-picker
          ref="picker"
          title="标题"
          :columns="postList"
          @confirm="onConfirm"
          @cancel="onCancel"
        >
          <template slot="columns-bottom">
            <div
              class="box"
              :style="{ 'padding-bottom': nativeBarHeight + 'px' }"
            >
              <div class="picker_btn">
                <div class="btn" @click="onCancel">取消</div>
                <div class="line"></div>
                <div class="btn" @click="$refs.picker.confirm()">确定</div>
              </div>
            </div>
          </template>
        </van-picker>
      </div>
    </van-popup>
  </div>
</template>

<script>
import FanChart from "../../components/FanChart";
import LineChart from "../../components/LineChart";
import { mapState } from "vuex";
export default {
  components: {
    FanChart,
    LineChart
  },
  computed: {
    ...mapState({
      nativeBarHeight: state => state.nativeBarHeight
    })
  },
  data() {
    return {
      postList: [],

      postValue: {},

      showPicker: false,

      dateType: 1,

      chartData: {},
      lineChartData: {}
    };
  },
  methods: {
    onConfirm(value) {
      this.postValue = value;
      console.log(value);
      this.showPicker = false;
    },
    onCancel() {
      this.showPicker = false;
    },
    clickShowPicker() {
      if (this.postList.length > 0) {
        this.showPicker = true;
      }
    },
    changeDataType(dataType) {
      this.dateType = dataType;
      this.requestLineChartData(this.postValue.id);
    },

    changeFanChartData(dateType) {
      this.requestFanChartData(this.postValue.id, dateType);
    },

    async requestFanChartData(id, dateType, callback) {
      let fanRes = await this.$api.getDayCharge({ stationId: id, dateType });
      // let fanRes = await this.$get(
      //   this.$config.TL_CHARGE_STATUS_GET_DAY_CHARGE,
      //   { stationId: id, dateType }
      // );

      console.log("fan res ==> ", fanRes);

      this.chartData = fanRes["data"]["data"]
        ? fanRes["data"]["data"]
        : this.$config.overviewData;

      if (callback) {
        callback(id);
      }
    },

    async requestLineChartData(id) {
      this.$tools.entering();
      // let lineRes = await this.$get(this.$config.TL_CHARGE_STATUS_GET_INCOME, {
      //   dateType: this.dateType,
      //   stationId: id
      // });
      let lineRes = await this.$api.getIncome({
        dateType: this.dateType,
        stationId: id
      });

      console.log("line res ==> ", lineRes);

      this.lineChartData = lineRes["data"]
        ? lineRes["data"]
        : this.$config.lineChartData;
    }
  },

  created() {
    // this.$get(this.$config.TL_CHARGE_STATUS_GET_STATION_BY_CAPTAIN, null)
    this.$api.getStationByCaptain().then(res => {
      console.log(res);
      if (res["data"]["data"]) {
        this.postList = res["data"]["data"];
        this.postValue = this.postList[0];
      } else {
        this.lineChartData = this.$config.lineChartData;
        this.chartData = this.$config.overviewData;
      }
    });
  },

  watch: {
    postValue(newV) {
      this.requestFanChartData(newV.id, null, this.requestLineChartData);
    }
  }
};
</script>

<style lang="less" scoped>
.post_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.post_top {
  height: 50px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  .top_title {
    display: flex;
    align-items: center;
    font-size: 16px;

    .icon {
      font-size: 22px;
      margin-right: 4px;
    }

    .title_text {
      font-size: 16px;
      font-weight: 600;
      color: #3a414b;
    }

    .title_num {
      color: #41c057;
      font-size: 22px;
      margin: 0 10px;
    }
  }

  .top_total {
    display: flex;
    align-items: center;
    font-size: 14px;
    border-radius: 25px;

    .total_num {
      color: #41be58;
      font-size: 22px;
      margin: 0 2px;
    }
  }
}

.post_fan {
  flex: 1;
  width: 100%;
  padding: 8px;

  .fan_box {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
  }
}

.post_discount {
  flex: 1;
  width: 100%;
  padding: 0 8px;

  .discount_box {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
  }
}

.post_report {
  font-size: 14px;
  padding: 4px 16px;
  border-radius: 20px;
  background-color: #a7d2f5;
  margin: 6px auto 0;
  color: #fff;
  margin-bottom: 6px;
  display: flex;
  align-items: center;

  .icon {
    font-size: 22px;
    margin-right: 6px;
  }
}

.pickerStyle {
  color: red;
}

.picker_btn {
  padding: 16px;
  height: 58px;
  font-size: 20px;
  color: #10967b;
  display: flex;
  align-items: center;

  .line {
    flex: 0 0 1px;
    height: 100%;
    width: 1px;
    border-left: 1px solid rgba(7, 17, 27, 0.3);
  }

  .btn {
    flex: 1;
    text-align: center;
  }
}
</style>
